import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HTMLPage from './htmlPage';
import downloadHoverImage from './assets/download_hover.png';

const imgVersion = 20241009
const isMac = /Macintosh|Mac OS X/i.test(navigator.userAgent);
const isMobile = /iPhone|iPad|iPod|Android|HarmonyOS/i.test(navigator.userAgent);

function isWeChatBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('micromessenger');
}

if (window.location.protocol === 'http:') {
  const hostname = window.location.hostname;
  const isDomain = /^[a-zA-Z0-9.-]+$/.test(hostname) && !/^\d{1,3}(\.\d{1,3}){3}$/.test(hostname);
  if (isDomain) {
      window.location.href = window.location.href.replace('http:', 'https:');
  }
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

const showToast = (text) => {
  const toast = document.createElement('div');
  toast.textContent = text;
  
  toast.style.position = 'fixed';
  toast.style.bottom = '20px';
  toast.style.left = '50%';
  toast.style.transform = 'translateX(-50%)';
  toast.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  toast.style.color = '#ffffff';
  toast.style.padding = '10px 20px';
  toast.style.borderRadius = '5px';
  toast.style.fontSize = '16px';
  toast.style.zIndex = '1000';
  toast.style.opacity = '0';
  toast.style.transition = 'opacity 0.3s ease';

  document.body.appendChild(toast);

  setTimeout(() => {
    toast.style.opacity = '1';
  }, 100); 

  setTimeout(() => {
    toast.style.opacity = '0';
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 300);
  }, 3000);
}; 

function handleWeChat() {
  if (!isWeChatBrowser()) {
    return;
  }
  function _handle() {
    const rewardPC = document.querySelector('.reward-banner-pc');
    const rewardMobile = document.querySelector('.reward-banner-mobile');
    const aboutQrCode = document.querySelector('.about-qrcode');
    const aboutContact = document.querySelector('.about-contact');

    if (rewardPC && rewardMobile && aboutQrCode && aboutContact) {
      rewardPC.style.display = 'none';
      rewardMobile.style.display = 'none';
      if (isMobile) {
        aboutQrCode.style.display = 'none';
        aboutContact.style.display = 'none';
      }
      return true;
    }
    return false;
  }

  if (_handle()) {
    return;
  }
  const observer = new MutationObserver(function(_, observerInstance) {
    if (_handle()) {
      observerInstance.disconnect();
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
}


function adjustFrame() {
  if (isMobile) {
    return;
  }
  function _handle() {
    const appTitle = document.querySelector('.app-title');
    const rewardBanner = document.querySelector('.reward-banner-pc');
  
    if (appTitle && rewardBanner) {
      const appTitleTop = appTitle.getBoundingClientRect().top;
      rewardBanner.style.top = `${appTitleTop - 30}px`;
      return true;
    }
    return false;
  }

  if (_handle()) {
    return;
  }
  const observer = new MutationObserver(function(_, observerInstance) {
    if (_handle()) {
      observerInstance.disconnect();
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

window.onload = function() {
  const images = document.querySelectorAll('img');
  images.forEach((img) => {
    img.oncontextmenu = function() {
      return false;
    };
  });

  adjustFrame();
  handleWeChat();
};

window.onresize = function() {
  adjustFrame();
}


/// HomePage 
///

function HomePage() {
  const currentDomain = window.location.hostname || 'offerji.cn';
  const downloadLink = 'https://offerji.res.oggy.chat/releases/mac/Offer%E9%B8%A1.dmg';
  const swal = withReactContent(Swal);

  const handleDownloadClick = () => {
    if (isMobile) {
      handleMobileDownloadClick();
    }
    else if (!isMac) {
      handleWindowsDownloadClick();
    }
    else {
      downloadApp();
    }
  };

  const handleMobileDownloadClick = () => {
    if (!isMobile) {
      return;
    }
    const copy_content = `https://${currentDomain}`
    swal.fire({
      html: `
        <div style="text-align: center;">
          <p style="color: #470B14; font-size: 16px; margin-bottom: 20px;">
            请复制以下链接在电脑端浏览器打开
          </p>
          <a href="${copy_content}" style="font-size: 16px; color: #000000; text-decoration: underline;" target="_blank">
            ${copy_content}
          </a>
        </div>
      `,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: '复制并关闭',
      customClass: {
        confirmButton: 'alert-button-single'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        copyText(copy_content);
      }
    });
  }

  const handleWindowsDownloadClick = () => {
    if (isMac) {
      return;
    }
    swal.fire({
      html: `
        <div style="text-align: center;">
          <p style="color: #470B14; font-size: 16px; margin-bottom: 20px;">
            暂时仅支持 macOS 系统，是否继续下载？
          </p>
        </div>
      `,
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      cancelButtonText: '取消',
      confirmButtonText: '继续下载',
      customClass: {
        confirmButton: 'alert-button-confirm',
        cancelButton: 'alert-button-cancel',
        actions: 'alert-actions'
      },
      width: '400px'
    }).then((result) => {
      if (result.isConfirmed) {
        downloadApp();
      }
    });
  }

  const downloadApp = () => {
    const link = document.createElement('a');
    link.href = downloadLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const copyText = (text) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text)
          .then(() => {
            showToast('复制成功');
          })
          .catch((err) => {
            console.error('navigator.clipboard.writeText failed 1:', err);
            fallbackCopyText(text);
          });
      }
      else {
        throw new Error('clipboard API is unsupported');
      }
    } catch (err) {
      console.error('navigator.clipboard.writeText failed 2:', err);
      fallbackCopyText(text)
    }
  }

  const fallbackCopyText = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
  
    // Prevent the textarea from affecting page layout
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    var successful = false;
    try {
      successful = document.execCommand('copy');
    } catch (err) {
      console.error('Fallback copy failed', err);
    }

    if (successful) {
      showToast('复制成功');
    } else {
      showToast('复制失败');
    }
  
    document.body.removeChild(textArea);
  }; 

  return (
    <div className="app">
      <header className="app-header">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/logo.png?v=${imgVersion}`}
          alt="Offer鸡 Logo"
          width="200"
          height="200"
          className="app-logo"
        />
        <h1 className="app-title">Offer鸡</h1>
        <p className="app-subtitle">AI助力面试，轻松拿Offer</p>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/reward_pc.png?v=${imgVersion}`}
          alt="下载注册即送AI面试时长"
          className="reward-banner-pc"
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/reward_mobile.png?v=${imgVersion}`}
          alt="下载注册即送AI面试时长"
          className="reward-banner-mobile"
        />
        <button className="download-button" onClick={handleDownloadClick}
          {...(!isMobile && { 
            onMouseEnter: (e) => (e.currentTarget.style.backgroundImage = `url(${downloadHoverImage}?v=${imgVersion})`),
            onMouseLeave: (e) => (e.currentTarget.style.backgroundImage = '')
          })}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/apple.png?v=${imgVersion}`}
            alt="macOS 下载"
            className="download-icon"
          />
          macOS 下载
        </button>
        <p className="app-supported-os">支持 macOS 12.0.0 及以上版本</p>
      </header>

      <section className="jobs-support-container">
        <h2 className="jobs-support-title">支持多岗位面试</h2>
        <img src={`${process.env.PUBLIC_URL}/assets/images/demo.png?v=${imgVersion}`} alt="支持多岗位面试" className="jobs-support-image" />
        <p className="jobs-support-demo-desc">操作简单，不用担心面试时手忙脚乱</p>
      </section>
      
      <section className="platform-support">
        <h2 className="platform-support-title">支持常见的远程面试平台</h2>
        <div className="platform-apps-image-container">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/meetings.png?v=${imgVersion}`}
            alt="支持常见的远程面试平台"
            className="platform-apps-image-pc"
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/meetings_mobile.png?v=${imgVersion}`}
            alt="支持常见的远程面试平台"
            className="platform-apps-image-mobile"
          />
        </div>
      </section>

      <section className="advantages-container">
        <h1 className="advantages-title">优势</h1>
        <div className="advantages-image-container advantage-1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/dual.png?v=${imgVersion}`} alt="双路语音实时采集和识别" className="advantages-image" />
          <p className="advantages-text">
            <span className="advantages-dual-part-1">双路语音</span>
            <span className="advantages-dual-part-2">实时采集和识别</span>
          </p>
        </div>
        <div className="advantages-image-container advantage-2">
          <img src={`${process.env.PUBLIC_URL}/assets/images/ai.png?v=${imgVersion}`} alt="最先进的AI大模型全程护航" className="advantages-image" />
          <p>最先进的AI大模型全程护航</p>
        </div>
        <div className="advantages-image-container advantage-3">
          <img src={`${process.env.PUBLIC_URL}/assets/images/meeting.png?v=${imgVersion}`} alt="适配主流会议软件" className="advantages-image" />
          <p>适配主流会议软件</p>
        </div>
        <div className="advantages-image-container advantage-4">
          <img src={`${process.env.PUBLIC_URL}/assets/images/jobs.png?v=${imgVersion}`} alt="全岗位支持" className="advantages-image" />
          <p>全岗位支持</p>
        </div>
        <div className="advantages-image-container advantage-5">
          <img src={`${process.env.PUBLIC_URL}/assets/images/archive.png?v=${imgVersion}`} alt="个人面试记录档案" className="advantages-image" />
          <p>个人面试记录档案</p>
        </div>
      </section>

      <section className="faq-container">
        <h1 className="faq-title">常见问题</h1>
        
        <div className="faq-item">
          <h2 className="faq-question">Offer鸡适用哪些岗位？</h2>
          <p className="faq-answer">适用于所有岗位，特别擅长各类知识和技术性强的岗位，针对性地帮助用户在面试临场填补知识空缺。</p>
        </div>
        
        <div className="faq-item">
          <h2 className="faq-question">Offer鸡能真正理解并快速回答面试问题吗？</h2>
          <p className="faq-answer">是的，Offer鸡基于最先进的实时语音识别和AI大模型技术，能实时了解当下用户的面试情况，生成个性化的回答。随着问答次数的增加，Offer鸡会越来了解用户经历，回答会越来越贴合用户的实际情况。</p>
        </div>
        
        <div className="faq-item">
          <h2 className="faq-question">Offer鸡在面试中使用是否会影响面试？</h2>
          <p className="faq-answer">Offer鸡主要针对线上面试，在面试中给用户提供回答的引导，包括回答的纲领和细节。在实际面试中引导话术和思路，让面试者有更多的底气和自信，也可以积累更多的面试经验和技巧。不过多影响当前面试者本身的行为和思路。面试结束后用户可以通过Offer鸡提供的面试记录进行复盘和总结。</p>
        </div>
      </section>

      <section className="about-container">
        <h1 className="about-title">关于我们</h1>
        
        <div className="about-content">
          <p className="about-description">前大厂的AI工程师合力打造<br/>最专业的AI面试辅助</p>
          <img src={`https://offerji.res.oggy.chat/images/contact_qrcode.jpg?v=${imgVersion}`} alt="客服群二维码" className="about-qrcode" />
          <p className="about-contact">欢迎加入客服群</p>
        </div>

        <footer className="about-footer">
          <p>粤ICP备2024279686号</p>
          <p>
            <a href="/terms">服务协议</a> 和 <a href="/privacy">隐私政策</a>
          </p>
        </footer>
      </section>
    </div>
  );
}

function TermsPage() {
  return <HTMLPage url="/pages/terms.html" />;
}

function PrivacyPage() {
  return <HTMLPage url="/pages/privacy.html" />;
}

function NotFoundPage() {
  return <HTMLPage url="/pages/404.html" />;
}

export default App;